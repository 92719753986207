import * as React from "react"

import Layout from "../components/layout"

const Privacy = () => (
  <Layout>
    <div className="m-32">
      <h1 className="mt-4 mb-4 text-3xl">Datenschutz</h1>
      <strong>Angaben gemäß Datenschutz-Grundverordnung (DSGVO):</strong>
      <p>Verantwortlicher für die Verarbeitung personenbezogener Daten im Sinne der Datenschutzgrundverordnung ist:</p>
      <strong className="mt-4 block">Lars Natus</strong>
      <p>Türkismühlerstraße 19</p>
      <p>66113 Saarbrücken</p>
      <p>hello [at] larsnatus.com</p>
      <p>USt-IdNr.: DE349764156</p>
      <h2 className="mt-4 mb-4 text-2xl">Verarbeitung von Daten</h2>
      <ul className="ml-4">
        <li>IP-Adresse</li>
        <li>Verwendetes Betriebssystem</li>
        <li>Browser-Version</li>
        <li>Datum und Uhrzeit</li>
        <li>Übertragene Daten</li>
        <li>Verweisende URL</li>
      </ul>
      <p className="mt-4">
        Die persönlichen Daten werden durch uns im Rahmen der Vorschriften des Telemediengesetzes (TMG), der EU Datenschutz-Grundverordnung (DSGVO) und des Bundesdatenschutzgesetzes (BDSG) erhoben und verarbeitet. 
        Ein Widerruf ist jederzeit möglich.
      </p>
      <h2 className="mt-4 mb-4 text-2xl">Kontaktformular</h2>
      <p>Auf unserer Seite bieten wir ein Kontaktformular an über das Sie mit uns in Verbindung treten können. 
        Folgende Informationen werden hierbei abgefragt:</p>
      <ul className="ml-4 mt-4">
        <li>Name</li>
        <li>E-Mail</li>
        <li>Benachrichtigung</li>
      </ul>
      <h2 className="mt-4 mb-4 text-2xl">Google Analytics</h2>
      <p>
        Diese Website nutzt Google Analytics, einen Webanalysedienst der Google Ireland Ltd. (Gordon House, Barrow Street, Dublin 4, Irland;), welcher Cookies verwendet.
      </p>
      <h2 className="mt-4 mb-4 text-2xl">YouTube</h2>
      <p>Die Vidoes von YouTube.com, welche wir auf unserer Website anbieten, werden durch YouTube (LLC, 901 Cherry Ave, San Bruno, CA 94066 USA), vertreten durch Google Inc., ausgeliefert</p>
      <h2 className="mt-4 mb-4 text-2xl">LinkedIn</h2>
      <p>Auf unserer Website verwenden wir einen Verweis (Link) auf das soziale Netzwerk LinkedIn, welches von der LinkedIn Inc. in den USA betrieben wird.</p>
     </div>
  </Layout>
)

export default Privacy
